import { ref, watch } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useTransactionList(currentUserId) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'description', label: 'Charge', sortable: true },
    { key: 'amount', sortable: true },
    { key: 'date', sortable: true, formatter: val => `$${val}` },
    { key: 'type', sortable: true },
  ]
  const perPage = ref(DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, statusFilter], () => {
    refetchData()
  })

  const fetchTransactionsList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      user_id: currentUserId,
    }
    store
      .dispatch('app-student/fetchTransactionList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Transactions list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'paid') return 'success'
    if (status === 'unpaid') return 'danger'
    return 'primary'
  }

  return {
    fetchTransactionsList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    resolveStatusVariant,

    refetchData,
  }
}
